// node modules
import React from 'react'
import styled from 'styled-components'
import { StaticImage, ImageDataLike } from 'gatsby-plugin-image'

// shared
import { Heading, Text } from 'components/shared/typography'
import LazyImage from 'components/shared/lazy-image'

// styles
import media from 'styles/media'

const Wrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.md.max} {
    width: 100%;
  }
`

const InnerWrapper = styled.div`
  padding-top: 20px;
  max-width: 70%;

  ${media.md.max} {
    max-width: 100%;
  }
`

const StyledHeading = styled(Heading)`
  font-size: 46px;

  ${media.md.max} {
    font-size: 30px;
  }
`

const StyledText = styled(Text)`
  ${media.md.max} {
    br {
      display: none;
    }
  }
`

const ImageWrapper = styled.div`
  ${media.md.max} {
    display: none;
  }
`

type Props = {
  image?: {
    src: ImageDataLike
    alt: string
  }
}

const Intro: React.FC<Props> = ({ image }) => {
  return (
    <Wrapper>
      <InnerWrapper>
        <StyledHeading
          as="h3"
          dangerouslySetInnerHTML={{
            __html: 'umów się na<br><i>jazdę próbną</i>',
          }}
          transform="uppercase"
          line="120%"
        />
        <StyledText
          dangerouslySetInnerHTML={{
            __html:
              'Umów się na jazdę próbną wymarzonym modelem CF MOTO!<br/> To bardzo proste. Wypełnij poniższy formularz<br/> i zaczekaj na kontakt.',
          }}
        />
        <ImageWrapper>
          {image?.src && <LazyImage src={image?.src} alt={image?.alt} />}
          {!image?.src && (
            <StaticImage src="../../../assets/images/atv.png" alt="" />
          )}
        </ImageWrapper>
      </InnerWrapper>
    </Wrapper>
  )
}

export default Intro
