import React from 'react'
import styled from 'styled-components'
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion'

import { Heading, Text } from 'components/shared/typography'
import Container from 'components/shared/container'
import LazyImage from 'components/shared/lazy-image'

import useBreakpoint from 'hooks/useBreakpoint'

import media from 'styles/media'

import { ImageDataLike } from 'gatsby-plugin-image'

const Wrapper = styled.div`
  margin-bottom: 100px;
`

const StyledHeading = styled(Heading)`
  ${media.lg.min} {
    position: relative;
    left: 50%;
  }
`

const DesktopWrapper = styled.div``

const ImageWrapper = styled.div`
  height: 300px;

  ${media.lg.min} {
    height: auto;
    width: 50%;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 0 30px 0;

  ${media.lg.min} {
    width: 50%;
    padding: 50px;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: column;

  ${media.lg.min} {
    flex-direction: row;
    margin: 0 auto;

    &:nth-child(even) {
      ${ImageWrapper} {
        order: 1;
      }
    }

    &:nth-child(1) {
      width: 50%;
    }

    &:nth-child(2) {
      width: 70%;
    }

    &:nth-child(3) {
      width: 85%;
    }

    &:nth-child(4) {
      /* width: 100%; */
    }
  }
`

const StyledAccordionItemButton = styled(AccordionItemButton)`
  padding: 20px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
`

const StyledAccordionItemPanel = styled(AccordionItemPanel)`
  animation: reveal 0.3s ease-out;

  @keyframes reveal {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`

type Props = {
  heading: string
  features: Array<{
    title: string
    text: string
    image: {
      src: ImageDataLike
      alt: string
    }
  }>
}

const Features: React.FC<Props> = ({ heading, features }) => {
  const { lg } = useBreakpoint()

  return (
    <Container>
      <Wrapper>
        <StyledHeading
          as="h4"
          transform="uppercase"
          weight={900}
          line="100%"
          size={67}
          dangerouslySetInnerHTML={{ __html: heading }}
          margin="40px"
        ></StyledHeading>

        {lg ? (
          <DesktopWrapper>
            {features.map(({ title, text, image }) => (
              <Row>
                <ImageWrapper>
                  <LazyImage src={image.src} alt={image.alt} fill />
                </ImageWrapper>
                <TextWrapper>
                  <Text weight={700} size={35} margin="20px" line="100%">
                    {title}
                  </Text>
                  <Text margin="0">{text}</Text>
                </TextWrapper>
              </Row>
            ))}
          </DesktopWrapper>
        ) : (
          <Accordion allowZeroExpanded={true}>
            {features.map(({ title, text, image }) => (
              <AccordionItem key={title}>
                <AccordionItemHeading>
                  <StyledAccordionItemButton>
                    {title}
                    <span>+</span>
                  </StyledAccordionItemButton>
                </AccordionItemHeading>
                <StyledAccordionItemPanel>
                  <Row>
                    <ImageWrapper>
                      <LazyImage src={image.src} alt={image.alt} fill />
                    </ImageWrapper>
                    <TextWrapper>
                      <Text
                        weight={700}
                        size={30}
                        margin="20px"
                        line="100%"
                        transform="uppercase"
                      >
                        {title}
                      </Text>
                      <Text margin="0">{text}</Text>
                    </TextWrapper>
                  </Row>
                </StyledAccordionItemPanel>
              </AccordionItem>
            ))}
          </Accordion>
        )}
      </Wrapper>
    </Container>
  )
}

export default Features
