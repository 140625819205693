import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import { Heading, Text } from 'components/shared/typography'
import LazyImage from 'components/shared/lazy-image'

import media from 'styles/media'

import { ImageDataLike } from 'gatsby-plugin-image'

const StyledContainer = styled(Container)`
  padding: 0;

  ${media.lg.min} {
    padding: 0 48px;
  }
`

const Wrapper = styled.div`
  margin: 30px 0 50px;

  ${media.lg.min} {
    display: flex;
    margin: 60px 0 100px;
  }
`

const MobileHeading = styled(Heading)`
  padding: 0 22px;

  ${media.lg.min} {
    display: none;
  }
`

const DesktopHeading = styled(Heading)`
  display: none;

  ${media.lg.min} {
    display: block;
  }
`

const ImageWrapper = styled.div`
  height: 440px;
  margin-bottom: 50px;

  ${media.lg.min} {
    height: auto;
    margin-bottom: 0;
    width: 50%;
    padding: 0 5%;
  }
`

const TextWrapper = styled.div`
  padding: 0 22px;

  ${media.lg.min} {
    width: 50%;
    padding: 0 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

type Props = {
  heading: string
  text: string
  image: {
    src: ImageDataLike
    alt: string
  }
}

const Introduction: React.FC<Props> = ({ heading, text, image }) => {
  return (
    <StyledContainer>
      <Wrapper>
        <MobileHeading
          as="h2"
          transform="uppercase"
          weight={900}
          line="100%"
          size={53}
          margin="50px"
          dangerouslySetInnerHTML={{ __html: heading }}
        />

        <ImageWrapper>
          <LazyImage src={image.src} alt={image.alt} fill />
        </ImageWrapper>
        <TextWrapper>
          <DesktopHeading
            as="h2"
            transform="uppercase"
            weight={900}
            line="100%"
            size={53}
            margin="50px"
            dangerouslySetInnerHTML={{ __html: heading }}
          />
          <Text dangerouslySetInnerHTML={{ __html: text }} />
        </TextWrapper>
      </Wrapper>
    </StyledContainer>
  )
}

export default Introduction
