import React from 'react'
import styled from 'styled-components'

import Player from 'components/shared/player'

import media from 'styles/media'

const Wrapper = styled.div`
  width: 100%;
  height: 440px;

  ${media.lg.min} {
    height: 100vh;
  }
`

type Props = {
  videoSrcUrl: string
  videoTitle: string
}

const VideoSection: React.FC<Props> = ({ videoSrcUrl, videoTitle }) => {
  return (
    <Wrapper>
      <Player videoSrcUrl={videoSrcUrl} videoTitle={videoTitle} />
    </Wrapper>
  )
}

export default VideoSection
