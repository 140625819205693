// node modules
import React from 'react'
import styled from 'styled-components'

// shared
import Container from 'components/shared/container'

// components
import Product from 'components/pages/category-page/products/product'

// styles
import media from 'styles/media'

// types
import type { ProductThumbnail } from 'types/product'

const Wrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
`

const InnerWrapper = styled.div``

const ProductsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 5%;
  row-gap: 20px;

  ${media.md.max} {
    gap: 20px;
  }
`

type Props = {
  products: ProductThumbnail[]
}

const Products: React.FC<Props> = ({ products }) => {
  return (
    <Wrapper>
      <Container>
        <InnerWrapper>
          <ProductsWrapper>
            {products.map((product) => (
              <Product key={product.name} product={product} />
            ))}
          </ProductsWrapper>
        </InnerWrapper>
      </Container>
    </Wrapper>
  )
}

export default Products
