// node modules
import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

// shared
import { Text } from 'components/shared/typography'
import LazyImage from 'components/shared/lazy-image'
import Icon from 'components/shared/icon'

// hooks
import useBreakpoint from 'hooks/useBreakpoint'

// utils
import parseProductCategory from 'utils/parseProductCategory'

// styles
import media from 'styles/media'

// assets
import arrowIcon from 'assets/icons/arrow-right-black.svg'

// types
import type { ProductThumbnail } from 'types/product'

const SingleProductWrapper = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
  text-decoration: none;
  width: 30%;
  cursor: pointer;

  span img {
    transition: 0.3s;
  }

  &:hover span img {
    transform: translateX(6px);
  }

  ${media.lg.max} {
    width: 47.5%;
  }

  ${media.sm.max} {
    width: 100%;
    flex-direction: row;
    gap: 10px;
  }
`
const ImageWrapper = styled.div`
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  ${media.sm.max} {
    overflow: visible;
    height: 100%;
    order: 1;
    width: 50%;
  }
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.sm.max} {
    width: 50%;
    align-items: flex-start;
    justify-content: center;

    img {
      display: none;
    }
  }
`

const Line = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  height: 1px;
  width: 50%;
  margin: 5px 0;
`

type Props = {
  product: ProductThumbnail
}

const Product: React.FC<Props> = ({ product }) => {
  const { sm, lg } = useBreakpoint()

  return (
    <SingleProductWrapper
      to={`/${parseProductCategory(product.category)}/${product.slug}`}
    >
      <ImageWrapper>
        <LazyImage
          src={product.image.src}
          alt={product.image.alt}
          fill
          objectFit={sm ? 'contain' : 'cover'}
        />
      </ImageWrapper>
      <InfoWrapper>
        <Text
          dangerouslySetInnerHTML={{ __html: product.name }}
          transform="uppercase"
          weight={700}
          size={lg ? 21 : 16}
          margin="0"
        />
        <Line />
        {product?.noPrice ? (
          <Text
            dangerouslySetInnerHTML={{
              __html: `<strong>${product.priceFrom}</strong>`,
            }}
            size={lg ? 17 : 14}
            margin={sm ? '10px' : '0'}
          />
        ) : (
          <Text
            dangerouslySetInnerHTML={{
              __html: `Cena od <strong>${product.priceFrom}</strong>`,
            }}
            size={lg ? 17 : 14}
            margin={sm ? '10px' : '0'}
          />
        )}
        <Icon src={arrowIcon} />
      </InfoWrapper>
    </SingleProductWrapper>
  )
}

export default Product
