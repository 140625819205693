import React from 'react'
import styled from 'styled-components'
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion'

import Container from 'components/shared/container'
import { Heading, Text } from 'components/shared/typography'
import Icon from 'components/shared/icon'

import media from 'styles/media'

import downloadIcon from 'assets/icons/download.svg'
import arrowIcon from 'assets/icons/arrow-right-white.svg'

const Wrapper = styled.section`
  padding: 30px 0 0 0;
  margin-bottom: 150px;

  ${media.lg.min} {
    padding: 50px 0 0 0;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`

const StyledLinkWithIcon = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;

  span {
    margin-right: 10px;
  }

  img {
    filter: invert();
  }
`

const StyledAccordionItem = styled(AccordionItem)`
  margin-bottom: 7px;
`

const StyledAccordionItemButton = styled(AccordionItemButton)`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.3s;

  img {
    min-width: 16px;
    transition: 0.3s;
    transform: rotate(90deg);
  }

  &[aria-expanded='true'] {
    background-color: ${({ theme }) => theme.colors.gray};
    color: ${({ theme }) => theme.colors.black};
    img {
      transform: rotate(-90deg);
      filter: invert();
    }
  }

  ${media.lg.min} {
    padding: 20px 40px;
  }
`

const StyledAccordionItemPanel = styled(AccordionItemPanel)`
  animation: reveal 0.3s ease-out;
  transform-origin: top;

  @keyframes reveal {
    0% {
      transform: scaleY(0);
    }

    100% {
      transform: scaleY(1);
    }
  }
`

const TechnicalDataItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  &:not(&:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.black};
  }

  p:first-child {
    font-weight: 500;
  }

  ${media.lg.min} {
    flex-direction: row;
    padding: 20px 40px;

    p:first-child {
      width: 30%;
    }
  }
`

type Props = {
  specs: Array<{
    name: string
    items: Array<{
      parameter: string
      value: string
    }>
  }>
  specsLink: string
}

const Specification: React.FC<Props> = ({ specs, specsLink }) => {
  return (
    <Container narrow>
      <Wrapper id="specs">
        <Row>
          {specs?.length > 0 && (
            <Heading
              as="h4"
              transform="uppercase"
              weight={700}
              line="100%"
              size={25}
              margin="0"
            >
              Specyfikacja
            </Heading>
          )}
          {specsLink && (
            <StyledLinkWithIcon href={specsLink} target="_blank">
              <Icon src={downloadIcon} width={20} height={20} />
              <Text margin={0} weight={700} transform="uppercase">
                Pobierz
              </Text>
            </StyledLinkWithIcon>
          )}
        </Row>
        <Accordion allowZeroExpanded={true}>
          {specs.map((category) => (
            <StyledAccordionItem key={category.name}>
              <AccordionItemHeading>
                <StyledAccordionItemButton>
                  {category.name}
                  <Icon src={arrowIcon} width={16} height={16} />
                </StyledAccordionItemButton>
              </AccordionItemHeading>
              <StyledAccordionItemPanel>
                {category.items.map(({ parameter, value }) => (
                  <TechnicalDataItem key={parameter}>
                    <Text transform="uppercase" margin={0}>
                      {parameter}
                    </Text>
                    <Text margin={0}>{value}</Text>
                  </TechnicalDataItem>
                ))}
              </StyledAccordionItemPanel>
            </StyledAccordionItem>
          ))}
        </Accordion>
      </Wrapper>
    </Container>
  )
}

export default Specification
