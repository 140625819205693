import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import SwiperInstance, { Scrollbar } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/scrollbar'

import { Heading } from 'components/shared/typography'
import LazyImage from 'components/shared/lazy-image'
import Icon from 'components/shared/icon'

import useBreakpoint from 'hooks/useBreakpoint'

import media from 'styles/media'

import arrowBlackIcon from 'assets/icons/arrow-right-black.svg'
import arrowWhiteIcon from 'assets/icons/arrow-right-white.svg'

import { ImageDataLike } from 'gatsby-plugin-image'

const Wrapper = styled.section`
  padding-top: 20px;
  padding-left: 22px;

  ${media.lg.min} {
    padding-left: 83px;
    padding-top: 50px;
  }

  ${media.xxxl.min} {
    padding-left: calc(((100vw - 1600px) / 2) + 83px);
  }
`

const StyledHeading = styled(Heading)`
  margin-bottom: 40px;
`

const SwiperWrapper = styled.div`
  position: relative;
  padding-bottom: 20px;

  .swiper-slide {
    width: 267px;
    height: 178px;

    ${media.lg.min} {
      width: 802px;
      height: 534px;
    }
  }

  .swiper-scrollbar.swiper-scrollbar-horizontal {
    position: static;
    margin-top: 20px;
  }

  .swiper-scrollbar-drag {
    background-color: ${({ theme }) => theme.colors.black};
    border-radius: 0;
    cursor: grab;
  }
`

const ControlButton = styled.button`
  display: none;

  ${media.lg.min} {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    height: 70px;
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    visibility: visible;
    opacity: 1;
    transition: 0.3s;

    img {
      transition: 0.3s;
    }

    ${({ hidden }) =>
      hidden &&
      css`
        visibility: hidden;
        opacity: 0;
      `}
  }
`

const StyledPrevButton = styled(ControlButton)`
  left: -35px;
  background-color: ${({ theme }) => theme.colors.black};

  img {
    transform: rotate(180deg);
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
    img {
      filter: invert();
    }
  }
`

const StyledNextButton = styled(ControlButton)`
  right: 48px;
  background-color: ${({ theme }) => theme.colors.white};

  ${media.xxxl.min} {
    right: calc(((100vw - 1600px) / 2) + 48px);
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.black};
    img {
      filter: invert();
    }
  }
`

type Props = {
  heading: string
  images: Array<{
    src: ImageDataLike
    alt: string
  }>
}

const Slider: React.FC<Props> = ({ heading, images }) => {
  const [swiper, setSwiper] = useState<SwiperInstance | null>(null)
  const [activeIndex, setActiveIndex] = useState(0)

  const goPrev = () => {
    swiper?.slidePrev()
  }

  const goNext = () => {
    swiper?.slideNext()
  }

  const { lg } = useBreakpoint()

  return (
    <Wrapper id="gallery">
      <StyledHeading
        as="h5"
        transform="uppercase"
        weight={900}
        line="100%"
        size={53}
        dangerouslySetInnerHTML={{ __html: heading }}
      ></StyledHeading>
      <SwiperWrapper>
        <Swiper
          modules={[Scrollbar]}
          onSwiper={(initSwiper: SwiperInstance) => setSwiper(initSwiper)}
          spaceBetween={lg ? 35 : 15}
          slidesPerView="auto"
          grabCursor
          scrollbar={{ draggable: true }}
          centeredSlides
          centeredSlidesBounds
          onSlideChange={() => setActiveIndex(swiper?.realIndex ?? 0)}
        >
          {images?.map(({ src, alt }, index) => (
            <SwiperSlide key={`${index}-${alt}`}>
              <LazyImage src={src} alt={alt} fill />
            </SwiperSlide>
          ))}
        </Swiper>

        <StyledPrevButton
          onClick={goPrev}
          aria-label="poprzedni slajd"
          hidden={activeIndex === 0}
        >
          <Icon src={arrowWhiteIcon} alt="" />
        </StyledPrevButton>
        <StyledNextButton
          onClick={goNext}
          aria-label="poprzedni slajd"
          /* eslint-disable no-unsafe-optional-chaining */
          hidden={activeIndex === swiper?.slides.length! - 1}
        >
          <Icon src={arrowBlackIcon} alt="" />
        </StyledNextButton>
      </SwiperWrapper>
    </Wrapper>
  )
}

export default Slider
