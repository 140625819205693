// node modules
import React, { useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import { Formik, Field, Form, FormikHelpers } from 'formik'
import axios from 'axios'

// shared
import { Text } from 'components/shared/typography'
import ButtonWithIcon from 'components/shared/button-with-icon'

// components
import Input from 'components/shared/modal-form/input'
import Checkbox from 'components/shared/modal-form/checkbox'

// hooks
import useLocation from 'hooks/useLocation'

// constants
import {
  TEST_DRIVE_FORM_SCHEMA,
  TEST_DRIVE_FORM_INIT_VALUES,
} from 'constants/form'

// styles
import media from 'styles/media'

// types
import type { TestDriveFormValues } from 'types/form'

const Wrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.md.max} {
    width: 100%;
    display: block;
  }
`

const StyledForm = styled(Form)`
  width: 350px;
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: 5px;
  }

  button {
    align-self: flex-start;
  }

  ${media.md.max} {
    width: 100%;
  }
`

const StyledTermsWrapper = styled.div`
  margin: 5px 0;
`

const StyledFormMessage = styled.p<{ error?: boolean }>`
  ${({ error }) =>
    error &&
    css`
      color: ${({ theme }) => theme.colors.danger};
    `};
`

const ContactForm = () => {
  const [formInfo, setFormInfo] = useState({
    text: '',
    error: false,
  })
  const ref = useRef(null)

  const path = useLocation()

  const currentUrl = `https://cfmoto.pl${path}`

  return (
    <Wrapper>
      <Formik
        innerRef={ref}
        initialValues={TEST_DRIVE_FORM_INIT_VALUES}
        validationSchema={TEST_DRIVE_FORM_SCHEMA}
        onSubmit={(
          values,
          { setSubmitting, resetForm }: FormikHelpers<TestDriveFormValues>
        ) => {
          if (formInfo.text && !formInfo.error) return
          ;(async () => {
            try {
              setSubmitting(true)

              const query = `
              mutation {
                sendTestDriveForm(
                  input: { name: "${values.name}", city: "${
                values.city
              }", email: "${values.email}", zipCode: "${
                values.postCode
              }", number: "${
                values.phone || ''
              }", url: "${currentUrl}", agree1: ${values.agree1}}
                ) {
                  code
                  status
                }
              }
              `

              await axios.post(`https://cms.cfmotoatv.pl/graphql`, {
                query,
              })

              setSubmitting(false)
              setFormInfo({
                text: 'Dziękujemy za wysłanie formularza!',
                error: false,
              })

              setTimeout(() => {
                setFormInfo({ text: '', error: false })
                resetForm()
              }, 5000)
            } catch (err) {
              setSubmitting(false)
              setFormInfo({ text: 'Coś poszło nie tak!', error: true })
              setTimeout(() => {
                setFormInfo({ text: '', error: false })
              }, 5000)
            }
          })()
        }}
      >
        {({ errors, touched, isSubmitting }) => (
          <StyledForm>
            <Field
              name="name"
              label="Imię i nazwisko"
              component={Input}
              required
            />

            <Field
              name="email"
              label="Adres e-mail"
              component={Input}
              required
            />

            <Field name="phone" label="Numer telefonu" component={Input} />

            <Field name="city" label="Miejscowość" component={Input} required />

            <Field
              name="postCode"
              label="Kod pocztowy"
              component={Input}
              required
            />

            <StyledTermsWrapper>
              <Field
                type="checkbox"
                name="agree1"
                label="Wyrażam zgodę na przetwarzanie moich danych osobowych podanych w formularzu kontaktowym w celu udzielenia odpowiedzi na zadane przeze mnie pytanie zgodnie z przepisami Ogólnego Rozporządzenia o Ochronie Danych Osobowych (RODO) i Polityki prywatności."
                component={Checkbox}
              />

              {Boolean(touched.agree1 && errors.agree1) && (
                <Text
                  dangerouslySetInnerHTML={{ __html: errors.agree1! }}
                  themecolor="danger"
                />
              )}
            </StyledTermsWrapper>

            {formInfo.text && (
              <StyledFormMessage error={formInfo.error}>
                {formInfo.text}
              </StyledFormMessage>
            )}

            <ButtonWithIcon
              disabled={!!(formInfo.text && !formInfo.error)}
              loading={isSubmitting}
            >
              Wyślij
            </ButtonWithIcon>
          </StyledForm>
        )}
      </Formik>
    </Wrapper>
  )
}

export default ContactForm
