// node modules
import React from 'react'
import styled from 'styled-components'

// shared
import Container from 'components/shared/container'
import LazyImage from 'components/shared/lazy-image'
import { Heading, Text } from 'components/shared/typography'
import ButtonWithIcon from 'components/shared/button-with-icon'

// components
import ActionBar from 'components/pages/product/hero/action-bar'

// styles

import media from 'styles/media'

// types

import { ImageDataLike } from 'gatsby-plugin-image'

const Wrapper = styled.section`
  ${media.md.min} {
    padding-bottom: 50px;
  }
`

const HeroWrapper = styled.div`
  height: calc(100vh + 50px);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${media.md.max} {
    height: 100vh;
  }
`

const HeroBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const HeroContentWrapper = styled.div`
  position: relative;
  padding-top: 10vh;

  h1 {
    margin-bottom: 20px;
  }

  p {
    font-size: 27px;
    margin-bottom: 20px;
  }

  ${media.md.max} {
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 50%;
    transform: translateX(-50%);
    top: 60%;
    width: 100%;
    padding: 0 35px;

    p {
      font-size: 18px;
    }
  }
`

type HeroProps = {
  image: {
    src: ImageDataLike
    alt: string
  }
  heading: string
  isPriceVisible: boolean
  priceFrom: string
  setFormOpened: (value: boolean) => void
  brochureLink: string
}

const Hero: React.FC<HeroProps> = ({
  image,
  heading,
  isPriceVisible,
  priceFrom,
  setFormOpened,
  brochureLink,
}) => {
  return (
    <Wrapper id="hero">
      <HeroWrapper>
        <HeroBackground>
          <LazyImage src={image.src} alt={image.alt} fill loading="eager" />
        </HeroBackground>
        <Container>
          <HeroContentWrapper>
            <Heading
              as="h1"
              dangerouslySetInnerHTML={{ __html: heading }}
              transform="uppercase"
              themecolor="white"
              weight={900}
              line="100%"
            />
            {isPriceVisible && (
              <Text
                dangerouslySetInnerHTML={{
                  __html: `Cena od <strong>${priceFrom}</strong>`,
                }}
                themecolor="white"
              />
            )}
            <ButtonWithIcon icon="helmet" onClick={() => setFormOpened(true)}>
              Jazda próbna
            </ButtonWithIcon>
          </HeroContentWrapper>
        </Container>
      </HeroWrapper>
      <ActionBar brochureLink={brochureLink} />
    </Wrapper>
  )
}

export default Hero
