// node modules
import React, { useState } from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout/layout'
import Seo from 'components/layout/seo'

// hooks
import usePopup from 'hooks/usePopup'

// shared
import Modal from 'components/shared/modal'
import Breadcrumbs from 'components/shared/breadcrumbs'

// components
import Hero from 'components/pages/product/hero'
import ModalForm from 'components/shared/modal-form'
import Introduction from 'components/pages/product/introduction'
import VideoSection from 'components/pages/product/video-section'
import Description from 'components/pages/product/description'
import Specification from 'components/pages/product/specification'
import Features from 'components/pages/product/features'
import Slider from 'components/pages/product/slider'
import OtherProducts from 'components/pages/product/other-products'

// types
import parseProductCategory from 'utils/parseProductCategory'

const Vehicle: React.FC<PageProps<Queries.VehiclePageQuery>> = ({ data }) => {
  const PAGE = data?.wpVehiclesArticle?.produkt
  const PAGE_SEO = data.wpVehiclesArticle?.seo!

  if (!PAGE || !PAGE_SEO) throw new Error("CMS data didn't load properly")

  const [formOpened, setFormOpened] = useState(false)

  const SPECIFICATION =
    PAGE?.productSpecification?.map((spec) => ({
      name: spec?.productSpecificationCategoryName ?? '',
      items:
        spec?.productSpecificationTechnicalData?.map((techData) => ({
          parameter: techData?.productSpecificationTechnicalDataParameter ?? '',
          value: techData?.productSpecificationTechnicalDataValue ?? '',
        })) || [],
    })) || []

  const FEATURES =
    PAGE?.productFeatures?.map((feature) => ({
      title: feature?.productFeatureTitle!,
      text: feature?.productFeatureDescription!,
      image: {
        src: feature?.productFeatureImage?.localFile?.childImageSharp
          ?.gatsbyImageData!,
        alt: feature?.productFeatureImage?.altText!,
      },
    })) || []

  const SLIDER_IMAGES =
    PAGE?.productGallery
      ?.filter(
        (image) =>
          image?.productGalleryImage?.localFile?.childImageSharp
            ?.gatsbyImageData
      )
      ?.map((image) => ({
        src: image?.productGalleryImage?.localFile?.childImageSharp
          ?.gatsbyImageData!,
        alt: image?.productGalleryImage?.altText ?? '',
      })) || []

  const OTHER_PRODUCTS =
    data?.allWpVehiclesArticle?.nodes
      ?.filter(
        (product) =>
          product?.produkt?.productOptionsProductName &&
          product?.produkt?.productOptionsProductImage
      )
      .filter(
        (product) =>
          product?.produkt?.productOptionsProductName !==
          PAGE?.productOptionsProductName
      )
      ?.map(({ date, slug, produkt }) => ({
        image: {
          src: produkt?.productOptionsProductImage?.localFile?.childImageSharp!
            ?.gatsbyImageData,
          alt: produkt?.productOptionsProductImage?.altText!,
        },
        name: produkt?.productOptionsProductName!,
        priceFrom: produkt?.productOptionsPriceFrom!,
        noPrice: Boolean(produkt?.productOptionsNoPrice),
        category: produkt?.productOptionsProductType!,
        dateAdded: date!,
        slug: slug!,
      }))
      .sort(
        (a, b) =>
          new Date(a.dateAdded).getTime() - new Date(b.dateAdded).getTime()
      ) || []

  const PRODUCT_COLOR_VERSIONS =
    PAGE?.productDescriptionColorVersions?.map((variant) => ({
      colorName: variant?.productDescriptionColorVersionsColorName!,
      image: {
        src: variant?.productDescriptionColorVersionsImage?.localFile
          ?.childImageSharp?.gatsbyImageData!,
        alt: variant?.productDescriptionColorVersionsImage?.altText!,
      },
    })) || []

  const POPUP = {
    image: {
      src: PAGE?.popupImg?.localFile?.childImageSharp?.gatsbyImageData!,
      alt: PAGE?.popupImg?.altText ?? '',
    },
    delay: PAGE?.popupDelay,
    link: PAGE?.popupLink ?? null,
    visibleOnMobile: PAGE?.popupVisibleOnMobile ?? false,
  }

  usePopup(POPUP)

  return (
    <Layout>
      <Seo
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Hero
        heading={PAGE?.productOptionsProductName!}
        image={{
          src: PAGE?.productHeroImage?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE?.productHeroImage?.altText!,
        }}
        isPriceVisible={!PAGE?.productOptionsNoPrice!}
        priceFrom={PAGE?.productOptionsPriceFrom ?? ''}
        setFormOpened={setFormOpened}
        brochureLink={PAGE?.productBrochureLink!}
      />
      <Modal active={formOpened} onClose={() => setFormOpened(false)} closeable>
        <ModalForm
          image={{
            src: PRODUCT_COLOR_VERSIONS?.[0]?.image?.src!,
            alt: PRODUCT_COLOR_VERSIONS?.[0]?.image?.alt!,
          }}
        />
      </Modal>
      <Breadcrumbs
        crumbs={[
          {
            name: parseProductCategory(PAGE?.productOptionsProductType!),
            to: `/${parseProductCategory(PAGE?.productOptionsProductType!)}/`,
          },
          { name: data?.wpVehiclesArticle?.title! },
        ]}
      />
      <Introduction
        heading={PAGE?.productIntroductionTitle!}
        image={{
          src: PAGE?.productIntroductionImage?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE?.productIntroductionImage?.altText!,
        }}
        text={PAGE?.productIntroductionDescription!}
      />
      {PAGE?.productVideoLink && (
        <VideoSection
          videoSrcUrl={PAGE?.productVideoLink!}
          videoTitle={PAGE?.productVideoTitle!}
        />
      )}
      <Description
        heading={PAGE?.productDescriptionHeading!}
        text={PAGE?.productDescriptionText!}
        model={PAGE?.productOptionsProductName!}
        priceFrom={PAGE?.productOptionsPriceFrom!}
        isPriceVisible={!PAGE?.productOptionsNoPrice!}
        colorVersions={PRODUCT_COLOR_VERSIONS}
        setFormOpened={setFormOpened}
      />
      <Specification
        specs={SPECIFICATION}
        specsLink={PAGE?.productSpecificationDownloadLink!}
      />
      {FEATURES.length > 0 && (
        <Features heading={PAGE?.productFeaturesHeading!} features={FEATURES} />
      )}
      {SLIDER_IMAGES.length > 0 && (
        <Slider heading={PAGE?.productGalleryHeading!} images={SLIDER_IMAGES} />
      )}
      {OTHER_PRODUCTS.length > 0 && (
        <OtherProducts
          heading={PAGE?.productOtherModelsHeading!}
          products={OTHER_PRODUCTS}
        />
      )}
    </Layout>
  )
}

export default Vehicle

export const query = graphql`
  query VehiclePage($slug: String, $category: String) {
    wpVehiclesArticle(slug: { eq: $slug }) {
      seo {
        ...WpSEO
      }
      title
      slug
      produkt {
        productHeroImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: DOMINANT_COLOR
                breakpoints: [1920]
              )
            }
          }
        }
        productBrochureLink
        productIntroductionTitle
        productIntroductionDescription
        productIntroductionImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 800)
            }
          }
        }
        productVideoLink
        productVideoTitle
        productDescriptionHeading
        productDescriptionText
        productDescriptionColorVersions {
          productDescriptionColorVersionsColorName
          productDescriptionColorVersionsImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 800)
              }
            }
          }
        }
        productSpecificationDownloadLink
        productSpecification {
          productSpecificationCategoryName
          productSpecificationTechnicalData {
            productSpecificationTechnicalDataParameter
            productSpecificationTechnicalDataValue
          }
        }
        productFeaturesHeading
        productFeatures {
          productFeatureTitle
          productFeatureDescription
          productFeatureImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 800)
              }
            }
          }
        }
        productGalleryHeading
        productGallery {
          productGalleryImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 800)
              }
            }
          }
        }
        productOtherModelsHeading
        productOptionsProductType
        productOptionsProductName
        productOptionsProductImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 500)
            }
          }
        }
        productOptionsPriceFrom
        productOptionsNoPrice
        productOptionsIsRecommended
        productOptionsIsPromotion
        productOptionsIsNew
        productOptionsRecommendedHeading
        productOptionsRecommendedDescription
        productOptionsVisibleInMenu
        popupImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        popupDelay
        popupLink
        popupVisibleOnMobile
      }
    }
    allWpVehiclesArticle(
      filter: { produkt: { productOptionsProductType: { eq: $category } } }
    ) {
      nodes {
        date
        slug
        produkt {
          productOptionsPriceFrom
          productOptionsNoPrice
          productOptionsProductName
          productOptionsProductType
          productOptionsProductImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 700)
              }
            }
          }
        }
      }
    }
  }
`
