import slugify from './slugify'

const parseProductCategory = (category: string) => {
  switch (category) {
    case 'Motocykl':
      return 'motocykle'
    default:
      return slugify(category)
  }
}

export default parseProductCategory
