// node modules
import React from 'react'
import styled from 'styled-components'

// shared
import Container from 'components/shared/container'
import { Text } from 'components/shared/typography'
import Icon from 'components/shared/icon'

// utils
import scrollToSection from 'utils/scrollToSection'

// styles
import media from 'styles/media'

// assets
import downloadIcon from 'assets/icons/download.svg'

const StyledContainer = styled(Container)`
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  ${media.lg.max} {
    position: relative;
    left: 0;
    transform: translate(0, 0);
    padding: 0;
  }
`

const Wrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.black};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  padding: 0 40px;

  ${media.lg.max} {
    flex-wrap: wrap;
    height: auto;
    padding: 0 1.35rem;
    align-items: flex-start;
  }
`

const Col = styled.div`
  &:not(&:last-child) {
    border-right: 1px solid ${({ theme }) => theme.colors.gray};
  }

  ${media.lg.max} {
    width: 100%;
    display: flex;
    align-items: center;

    &:not(&:last-child) {
      border-right: 0;
    }

    &:not(&:first-child) {
      border-top: 1px solid ${({ theme }) => theme.colors.gray};
    }
  }
`

const StyledLink = styled.button`
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  width: 100%;
  border: none;
  background: none;
  padding: 25px 0;

  ${media.lg.min} {
    justify-content: center;
    padding: 10px 60px;
  }
`

const StyledLinkWithIcon = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;

  span {
    margin-right: 10px;
  }

  ${media.lg.min} {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
  }
`

type Props = {
  brochureLink: string
}

const ActionBar: React.FC<Props> = ({ brochureLink }) => {
  return (
    <>
      <StyledContainer>
        <Wrapper>
          <Col>
            <StyledLink onClick={() => scrollToSection('#general')}>
              <Text
                themecolor="white"
                margin={0}
                weight={700}
                size={17}
                transform="uppercase"
              >
                Informacje ogólne
              </Text>
            </StyledLink>
          </Col>
          <Col>
            <StyledLink onClick={() => scrollToSection('#specs')}>
              <Text
                themecolor="white"
                margin={0}
                weight={700}
                size={17}
                transform="uppercase"
              >
                Specyfikacja
              </Text>
            </StyledLink>
          </Col>
          <Col>
            <StyledLink onClick={() => scrollToSection('#gallery')}>
              <Text
                themecolor="white"
                margin={0}
                weight={700}
                size={17}
                transform="uppercase"
              >
                Galeria
              </Text>
            </StyledLink>
            {brochureLink && (
              <StyledLinkWithIcon href={brochureLink} target="_blank">
                <Icon src={downloadIcon} width={25} height={25} />
                <Text
                  themecolor="white"
                  margin={0}
                  size={13}
                  transform="uppercase"
                >
                  pobierz
                  <br />
                  broszurę
                </Text>
              </StyledLinkWithIcon>
            )}
          </Col>
        </Wrapper>
      </StyledContainer>
    </>
  )
}

export default ActionBar
