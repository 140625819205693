// node modules
import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'

// shared
import Button from 'components/shared/button'
import Icon from 'components/shared/icon'

// hooks
import useOutsideClick from 'hooks/useOutsideClick'

// styles
import media from 'styles/media'

// assets
import closeIcon from 'assets/icons/close.svg'

// types
import type { ActiveRequired } from 'types/shared/active'

type Props = {
  closeable?: boolean
  onClose?: () => void
  children: React.ReactNode
  noCloseButton?: boolean
} & ActiveRequired

const Overlay = styled.div<ActiveRequired>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 400;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  transition-property: opacity, visibility;
  opacity: 0;
  visibility: hidden;
  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`

const Content = styled.div<ActiveRequired>`
  position: relative;
  max-width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0;
  visibility: hidden;
  transform: translate(0, 60px);
  transition: 0.35s ease;

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      visibility: visible;
      transform: translate(0, 0) !important;
    `}
`

const Close = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 5;
  ${media.lg.max} {
    right: -5px;
    top: -20px;
  }

  /* change close icon color on hover  */
  img {
    transition: 0.2s;
  }
  &:hover {
    img {
      filter: invert();
    }
  }
`

const Modal: React.FC<Props> = ({
  children,
  active,
  closeable,
  onClose,
  noCloseButton,
}) => {
  const [opened, setOpened] = useState<boolean>(active ?? false)
  const modalRef = useRef(null)
  useEffect(() => {
    setOpened(active)
  }, [active])

  const closeModal = () => {
    setOpened(false)
    if (onClose) {
      onClose()
    }
  }

  useOutsideClick({
    ref: modalRef,
    handler: closeModal,
    condition: opened,
  })

  return (
    <Overlay active={opened}>
      <Content active={opened} ref={modalRef}>
        {closeable && !noCloseButton && (
          <Close>
            <Button onClick={closeModal} black round roundSize="60px">
              <Icon src={closeIcon} />
            </Button>
          </Close>
        )}
        {children}
      </Content>
    </Overlay>
  )
}

export default Modal
