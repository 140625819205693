import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { Link } from 'gatsby'

import Container from 'components/shared/container'
import { Heading, Text } from 'components/shared/typography'
import LazyImage from 'components/shared/lazy-image'
import ButtonWithIcon from 'components/shared/button-with-icon'

import media from 'styles/media'

import { ImageDataLike } from 'gatsby-plugin-image'

const Wrapper = styled.section`
  padding: 30px 0 0;

  ${media.lg.min} {
    padding: 100px 0 0;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: column;

  ${media.lg.min} {
    flex-direction: row;
  }
`

const Col = styled.div`
  width: 100%;

  ${media.lg.min} {
    width: 50%;
  }
`

const ActionsRow = styled(Row)`
  margin-top: 10px;
  ${Col} {
    margin-top: 10px;
  }

  a {
    width: 240px;
    margin-bottom: 10px;
  }

  button {
    width: 240px;
  }

  ${media.lg.min} {
    a {
      width: 49%;
      margin-bottom: 0;
      padding: 20px;
    }

    button {
      width: 49%;
      margin-left: 2%;
      padding: 20px;
    }
  }
`

const show = keyframes`
    from {
      transform: scale(0);
      
    }
    to {
      transform: scale(1);
    }

`

const ImageWrapper = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;

  img {
    transform: scale(0);
    animation: ${show} 0.3s forwards;
  }
`

const ColorPalette = styled.div`
  display: flex;
  margin: 0 auto;
  width: fit-content;
`

const ColorTooltip = styled.div`
  position: absolute;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
  left: 50%;
  transform: translate(-50%, -100%) scale(0);
  font-size: 13px;

  ${media.lg.min} {
    font-size: 16px;
  }
`

const ColorToggle = styled.button`
  position: relative;
  border: none;
  background: transparent;
  width: 50px;
  height: 50px;
  margin-right: 7px;
  padding: 0;

  ${media.lg.min} {
    margin-right: 10px;
    cursor: pointer;
  }

  &:hover {
    ${ColorTooltip} {
      transform: translate(-50%, -100%) scale(1);
      opacity: 1;
      visibility: visible;
      line-height: 100%;
    }
  }
`

type Props = {
  heading: string
  text: string
  model: string
  priceFrom: string
  isPriceVisible: boolean
  colorVersions: Array<{
    colorName: string
    image: {
      src: ImageDataLike
      alt: string
    }
  }>
  setFormOpened: (value: boolean) => void
}

const Description: React.FC<Props> = ({
  heading,
  text,
  model,
  priceFrom,
  isPriceVisible,
  colorVersions,
  setFormOpened,
}) => {
  const [activeImageIndex, setActiveImageIndex] = useState(0)

  return (
    <Container narrow>
      <Wrapper id="general">
        <Row>
          <Col>
            <Heading
              as="h4"
              transform="uppercase"
              weight={900}
              line="100%"
              size={53}
              margin="40px"
              dangerouslySetInnerHTML={{ __html: heading }}
            />
          </Col>
          <Col>
            <Text dangerouslySetInnerHTML={{ __html: text }} />
          </Col>
        </Row>
        <ActionsRow>
          <Col>
            <Text
              size={20}
              weight={700}
              margin="5px"
              dangerouslySetInnerHTML={{ __html: model }}
            />
            {isPriceVisible && (
              <Text
                dangerouslySetInnerHTML={{
                  __html: `Cena od <strong>${priceFrom}</strong>`,
                }}
              />
            )}
          </Col>
          <Col>
            <ButtonWithIcon icon="pin" as={Link} to="/znajdz-dealera">
              Znajdź dealera
            </ButtonWithIcon>
            <ButtonWithIcon
              white
              icon="helmet"
              onClick={() => setFormOpened(true)}
            >
              Jazda próbna
            </ButtonWithIcon>
          </Col>
        </ActionsRow>
        <ImageWrapper>
          <LazyImage
            src={colorVersions[activeImageIndex]?.image?.src}
            alt={colorVersions[activeImageIndex]?.image?.alt}
          />
        </ImageWrapper>
        <ColorPalette>
          {colorVersions?.map(({ colorName, image }, index) => (
            <ColorToggle onClick={() => setActiveImageIndex(index)}>
              <ColorTooltip dangerouslySetInnerHTML={{ __html: colorName }} />
              <LazyImage
                src={image?.src}
                alt={image?.alt}
                objectFit="contain"
              />
            </ColorToggle>
          ))}
        </ColorPalette>
      </Wrapper>
    </Container>
  )
}

export default Description
