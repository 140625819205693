// node modules
import React from 'react'
import styled from 'styled-components'

// components
import Intro from 'components/shared/modal-form/intro'
import ContactForm from 'components/shared/modal-form/contact-form'

// styles
import media from 'styles/media'

// types
import { ImageDataLike } from 'gatsby-plugin-image'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1400px;
  width: 90vw;
  background: ${({ theme }) => theme.colors.white};
  padding-top: 2rem;
  padding-bottom: 2rem;
  max-height: 85vh;
  overflow-y: auto;

  ${media.lg.max} {
    padding: 20px;
  }

  ${media.md.max} {
    height: 80vh;
    display: block;
  }
`

type Props = {
  image?: {
    src: ImageDataLike
    alt: string
  }
}

const ModalForm: React.FC<Props> = ({ image }) => {
  return (
    <Wrapper>
      <Intro image={image} />
      <ContactForm />
    </Wrapper>
  )
}

export default ModalForm
