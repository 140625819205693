import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import { Heading } from 'components/shared/typography'

import Products from 'components/pages/category-page/products'

import media from 'styles/media'

import type { ProductThumbnail } from 'types/product'

const Wrapper = styled.div`
  padding: 80px 0 40px;

  ${media.lg.min} {
    padding: 150px 0 75px;
  }
`

type Props = {
  heading: string
  products: ProductThumbnail[]
}

const OtherProducts: React.FC<Props> = ({ heading, products }) => {
  return (
    <Container>
      <Wrapper>
        <Heading
          as="h6"
          transform="uppercase"
          weight={900}
          line="100%"
          size={44}
          align="center"
          margin="40px"
          dangerouslySetInnerHTML={{ __html: heading }}
        />
        <Products products={products} />
      </Wrapper>
    </Container>
  )
}

export default OtherProducts
